import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ISidebarItem } from "../interfaces/ISidebarItem";
import { webRoutes } from "../../../core/constants/routes";

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  BaseRoutes = `/`;
  private items: ISidebarItem[] = [
    {
      title: "DASHBOARD",
      routerLink: this.BaseRoutes + webRoutes.dashboard,
      icon: "dashboard_2",
      userTypes: ["marveler", "counselor"],
    },
    {
      title: "PROFILE",
      routerLink: this.BaseRoutes + webRoutes.profile,
      icon: "person",
      userTypes: ["marveler", "counselor"],
    },
    {
      title: "BOOKINGS",
      routerLink: this.BaseRoutes + webRoutes.booking,
      icon: "calendar_month",
      userTypes: ["marveler", "counselor"],
    },
    {
      title: "PAYMENT",
      routerLink: this.BaseRoutes + webRoutes.payment,
      icon: "credit_card",
      userTypes: ["marveler", "counselor"],
    },
    {
      title: "FAVORITE COUNSELORS",
      routerLink: this.BaseRoutes + webRoutes.favCounselors,
      icon: "diversity_1",
      userTypes: ["marveler"],
    },
    {
      title: "AVAILABILITY",
      routerLink: this.BaseRoutes + webRoutes.availability,
      icon: "event_available",
      userTypes: ["counselor"],
    },
    {
      title: "PRICES",
      routerLink: this.BaseRoutes + webRoutes.pricing,
      icon: "euro",
      userTypes: ["counselor"],
    },
    {
      title: "EARNING",
      routerLink: this.BaseRoutes + webRoutes.earning,
      icon: "price_change",
      userTypes: ["counselor"],
    },
    {
      title: "REVIEWS",
      routerLink: this.BaseRoutes + webRoutes.reviews,
      icon: "feedback",
      userTypes: ["marveler", "counselor"],
    },
    {
      title: "GALLERY",
      routerLink: this.BaseRoutes + webRoutes.gallery,
      icon: "photo_camera",
      userTypes: ["marveler", "counselor"],
    },
    {
      title: "SETTINGS",
      routerLink: this.BaseRoutes + webRoutes.settings,
      icon: "settings",
      userTypes: ["marveler", "counselor"],
    },
  ];

  private sidebarItems = new BehaviorSubject<ISidebarItem[]>(this.items);
  private sidebarState = new BehaviorSubject<boolean>(false);

  constructor() {}

  public get navItems(): Observable<ISidebarItem[]> {
    return this.sidebarItems.asObservable();
  }

  public get getSidebarState(): Observable<boolean> {
    return this.sidebarState.asObservable();
  }

  public setSidebarState(state: boolean) {
    this.sidebarState.next(state);
  }

  public toggleSidebar() {
    this.sidebarState.next(!this.sidebarState.value);
  }
}
