import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterLink, RouterModule } from "@angular/router";
import { SidebarService } from "./services/sidebar.service";
import { ISidebarItem } from "./interfaces/ISidebarItem";
import { MatIconModule } from "@angular/material/icon";
import { RequestService } from "../../shared/services/request.service";

@Component({
  selector: "app-sidebar",
  standalone: true,
  imports: [CommonModule, RouterLink, RouterModule, MatIconModule],
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  items: ISidebarItem[] = [];
  currentUser: any;

  constructor(
    private sidebarService: SidebarService,
    private requestService: RequestService
  ) {}

  ngOnInit() {
    this.currentUser = this.requestService.currentUser;
    if (this.currentUser.role == "counselor") {
      document.documentElement.style.setProperty("--primary-color", "#12676A");
      document.documentElement.style.setProperty("--secondary-color", "9D0759");
      document.documentElement.style.setProperty(
        "--secondary-color-rgb",
        "18, 103, 106"
      );
      document.documentElement.style.setProperty(
        "--primary-color-rgb",
        "157, 7, 89"
      );
    } else {
      document.documentElement.style.setProperty(
        "--secondary-color",
        "#12676A"
      );
      document.documentElement.style.setProperty("--primary-color", "#9D0759");
      document.documentElement.style.setProperty(
        "--secondary-color-rgb",
        "157, 7, 89"
      );
      document.documentElement.style.setProperty(
        "--primary-color-rgb",
        "18, 103, 106"
      );
    }

    this.sidebarService.navItems.subscribe((items: ISidebarItem[]) => {
      this.items = items;
    });
  }

  trackByTitle(index: number, item: ISidebarItem): string {
    return item.title;
  }
  logout(): void {
    this.requestService.logout();
  }
}
