<aside [ngStyle]="{ 'background-color': 'var(--primary-color)' }">
  <div class="listing">
    <ul>
      <ng-container  *ngFor="let item of items; trackBy: trackByTitle">
        <li *ngIf="item.userTypes?.includes(currentUser.role)"
        #rla="routerLinkActive"
        [routerLinkActive]="['active']"
        [ngClass]="{ active: rla.isActive }"
        [ngStyle]="{
          'background-color': rla.isActive ? 'var(--primary-color)' : ''
        }"
      >
      <mat-icon class="img">{{item.icon}}</mat-icon>
        <a
          [routerLink]="item.routerLink"
          routerLinkActive="active"
          [ngStyle]="{ color: rla.isActive ? 'white' : 'var(--primary-color)' }"
          >{{ item.title }}</a
        >
      </li>
      </ng-container>
    </ul>
    <ul>
      <li>
        <mat-icon class="img">logout</mat-icon>
        <a (click)="logout()" [ngStyle]="{ color: 'var(--primary-color)' }">Logout</a>
      </li>
    </ul>
  </div>
</aside>
